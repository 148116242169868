<template>
  <div class="task-list">
    <SimpleTable
      ref="taskList"
      maxHeight="440px"
      noSerial
      showChoose
      showDelete
      showListBtn
      showOperate
      showPreview
      isDisabledClick
      :showBtn2="false"
      :tableData="taskListData"
      :columnData="taskListColumn"
      :initObj="taskListPageObj"
      :contentData="taskListContentData"
      @onRowClick="handleRowClick"
      @onClickBtn1="handlePulish"
      @onClickTitle="handleTaskListOpenUrl"
      @onChangeToTop="handleToTop"
      @onChangeToBottom="handleToBottom"
      @onChangeDeleteItems="handleDeleteItems"
      @onHandlePreviewURL="handlePreviewURL"
      @onHandlePreviewJump="handlePreviewJump"
      @onChangeInputBlur="handleTaskChangeInputBlur"
      @onChangePageData="
        (pageObj) => {
          getTaskList(pageObj)
        }
      "
    />
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable'
import { GetHistoryList, UpOrBottom, batchDelete } from '@/api/selfPush'
import { UpdateJournalPeriod, GetNetworkJournalInfo } from '@/api/weChatPush'
import { WeChatAllJumpUrl } from '@/assets/js/util'
import { mapGetters } from 'vuex'
import QRCode from 'qrcodejs2'
export default {
  name: 'taskList',
  components: {
    SimpleTable
  },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    // 内容类型为全部时才允许移动
    showUpBottom() {
      return this.taskType === ''
    }
  },
  props: {
    chooseTaskId: {
      type: String,
      default: ''
    },
    templateType: {
      type: Number,
      default: null
    },
    taskType: {
      default: ''
    }
  },
  data() {
    return {
      taskListData: [],
      taskListColumn: [
        {
          title: '微网刊主题',
          prop: 'sysTopic',
          notSortable: true,
          special: true
        },
        {
          title: '内容类型',
          prop: 'type',
          notSortable: true,
          width: '100px'
        },
        {
          title: '年份',
          prop: 'year',
          notSortable: true,
          width: '100px',
          edit: false
        },
        {
          title: '刊期',
          prop: 'issue',
          notSortable: true,
          width: '100px',
          edit: false
        },
        {
          title: '生成时间',
          prop: 'sysCreated',
          notSortable: true,
          width: '160px'
        },
        {
          title: '状态',
          prop: 'isPublish',
          width: '80px',
          notSortable: true
        }
      ],
      taskListPageObj: {
        page: 1,
        size: 10,
        sort: 'year,issue,desc'
      },
      taskListContentData: {},
      chooseItem: ''
    }
  },
  watch: {
    magId() {
      this.getTaskList()
    },
    chooseItem() {
      // 专题年期可编辑 整期不可编辑
      if (this.chooseItem.type === '整期') {
        this.taskListColumn[2].edit = false
        this.taskListColumn[3].edit = false
      } else {
        this.taskListColumn[2].edit = true
        this.taskListColumn[3].edit = true
      }
    }
  },
  mounted() {
    if (this.magId) {
      this.getTaskList()
    }
  },
  methods: {
    // 获取任务列表数据
    getTaskList(pageParams = {}, type, item) {
      let { page = 1, size = 10, sort = 'year,issue,desc' } = pageParams
      let params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort,
        type: this.taskType
      }
      GetHistoryList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.taskListPageObj = {
            ...this.taskListPageObj,
            page,
            size,
            total: data.totalElements
          }
          this.taskListContentData = data
          this.taskListData = data.content
          this.$emit('input', this.taskListData)
          if (this.taskListData.length > 0) {
            this.taskListData.map((item, index) => {
              if (item.type === 1) {
                item.type = '整期'
              } else if (item.type === 2) {
                item.type = '专题'
              } else if (item.type === 3) {
                item.type = '通知'
              }
              item.btn1Text = item.isPublish === 1 ? '撤销发布' : '发布'
              item.activeBtn1 = item.templateId ? true : false
              item.isPublish = item.isPublish === 1 ? '已发布' : '未发布'
            })
            this.$nextTick(() => {
              if (type === 'move') {
                // this.$refs.taskList.handleRowClick(item)
                this.$refs.taskList.initData()
                this.$refs.taskList.$refs.simpleTable.clearSelection()
              } else if (type === 'edit') {
                this.taskListData.map((it) => {
                  if (it.id === item) {
                    this.$refs.taskList.handleRowClick(it)
                  }
                })
              } else if (type === 'publish') {
                this.taskListData.map((it) => {
                  if (it.id === item) {
                    this.$refs.taskList.handleRowClick(it)
                  }
                })
              } else {
                this.$refs.taskList.handleRowClick(this.taskListData[0])
              }
            })
          } else {
            this.$emit('getTaskList', this.taskListData)
            this.$refs.taskList.initData()
            this.handleRowClick('')
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 任务列表点击标题跳转
    handleTaskListOpenUrl(chooseItem, columnItem) {
      const { journalUrl = '' } = chooseItem
      if (journalUrl && journalUrl !== '') {
        window.open(journalUrl)
      }
    },
    // 任务列表数据上移
    handleToTop(index, chooseId, item) {
      this.articleUpOrBottom(item.id, 'up', item)
    },
    // 任务列表数据下移
    handleToBottom(index, chooseId, item) {
      this.articleUpOrBottom(item.id, 'down', item)
    },
    // 任务列表数据上移下移接口
    articleUpOrBottom(id, type, item) {
      let params = {
        id: id,
        type: type === 'up' ? 1 : -1
      }
      UpOrBottom(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '移动成功!'
          })
          this.getTaskList(this.taskListPageObj, 'move', item)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 处理任务列表数据删除
    handleDeleteItems(chooseId) {
      let params = {
        ids: chooseId
      }
      batchDelete(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getTaskList()
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleRowClick(chooseId) {
      this.taskListData.map((item) => {
        if (item.id === chooseId) {
          this.chooseItem = item
        }
      })
      this.$emit('changeTask', chooseId)
    },
    //任务列表预览
    handlePreviewURL(value) {
      let params = {
        id: value.id
      }
      GetNetworkJournalInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data.templateType) {
            this.$refs.taskList.showQrcode = true
            this.$nextTick(() => {
              document.getElementById(`qrcode${value.id}`).innerHTML = ''
              let qrcode = new QRCode(`qrcode${value.id}`, {
                width: 120,
                height: 120,
                colorDark: '#000',
                colorLight: '#fff'
              })
              qrcode.makeCode(WeChatAllJumpUrl(this.magId, value.id))
            })
          } else {
            this.$refs.taskList.showQrcodeText = '请配置模板！'
            this.$refs.taskList.showQrcode = false
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    //任务列表预览跳转
    handlePreviewJump(value) {
      window.open(WeChatAllJumpUrl(this.magId, value.id))
    },
    //任务列表任务为专题时支持年期修改
    handleTaskChangeInputBlur(val, prop, event, index, content) {
      let params = {
        id: this.taskListData[index].id,
        year: prop === 'year' ? content : this.taskListData[index].year,
        issue: prop === 'issue' ? content : this.taskListData[index].issue
      }
      UpdateJournalPeriod(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.getTaskList(this.taskListPageObj, 'edit', this.taskListData[index])
        }
      })
    },
    // 任务列表发布撤销发布
    handlePulish() {
      this.$emit('updateJournalPublish')
    }
  }
}
</script>

<style lang="scss">
.task-list {
  .delete,
  .up-bottom-btns {
    float: left;
  }
}
</style>
